import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languageDetector = new LanguageDetector();
i18next.use(resourcesToBackend((language, namespace, callback) => {
    import(`./translations/${language}/${namespace}.json`)
        .then((resources) => {
            callback(null, resources)
        })
        .catch((error) => {
            callback(error, null)
        })
})).use(languageDetector).init({
    supportedLngs: ['nl', 'en'],
    nonExplicitSupportedLngs: true,
    interpolation: { escapeValue: false },
    returnObjects: true,
    detection: {
        order: ['querystring', 'htmlTag', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
        caches: ['localStorage', 'cookie']
    }
});

const root = ReactDOM.createRoot(document.getElementById('lsc-root'));
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
