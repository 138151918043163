import './App.css';
import React, {Component, createRef, useRef, createContext, useContext, useEffect} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import {ReactComponent as TelephoneIcon} from './img/TelephoneLittleSheep.svg'

const LocaleContext = createContext('nl-NL');

function formatPrice(price, locale = 'nl-NL', digits = 0, symbol = false) {
    let options = {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
    }
    if (symbol) {
        options = {
            ...options,
            style: 'currency',
            currency: 'EUR'
        }
    }
    return parseFloat(price).toLocaleString(locale, options);
}

function formatNumber(number, locale = 'nl-NL') {
    return parseFloat(number).toLocaleString(locale);
}

function getOptionPrice(optionPriceData, optionState, allOptionsState) {
    let optionPrice;
    switch (optionPriceData.type) {
        case 'radio':
            optionPrice = optionState ? optionPriceData.values[optionState] : 0;
            break;
        case 'check':
            optionPrice = optionState ? optionPriceData.value : 0;
            break;
        case 'unit':
            optionState = optionState ? parseFloat(optionState) : 0.0;
            let modifyValue = optionPriceData.modifyValue || 0;
            optionPrice = (optionState + modifyValue) * optionPriceData.value;
            break;
        case 'depend':
            let dependValue = allOptionsState[optionPriceData.on];
            optionPrice = dependValue ? optionPriceData.values[dependValue] : 0;
            break;
        default:
            optionPrice = 0;
            break;
    }
    if (typeof optionPrice === "number") {
        return optionPrice;
    } else {
        return getOptionPrice(optionPrice, optionState, allOptionsState);
    }
}

class App extends Component {
    static defaultOptions = {
        style: '',
        duration: 1.0,
        subtitles: false,
        multipleLanguages: '',
        teaser: false,
        teaserMultipleLanguages: '',
        images: false,
        deadline: '1month'
    }

    static defaultState = {
        ...App.defaultOptions,
        wishes: '',
        name: '',
        company: '',
        email: '',
        phone: '',
        discountCode: '',
        discount: false,
        valid: {
            wishes: false,
            name: false,
            company: false,
            email: false,
            phone: false
        },
        hadFocus: {
            wishes: false,
            name: false,
            company: false,
            email: false,
            phone: false
        },
        submitError: false,
        pendingAlert: false
    }

    static apiUrl = 'https://configurator.littlesheepanimatie.nl/api';

    static fieldPatterns = {
        style: /^(?:low|middle|high)$/,
        wishes: /^[\s\S]{20,}$/,
        name: /^.{2,}$/,
        company: /^.{2,}$/,
        email: /^\S+@\S+\.\S+$/,
        phone: /^[\d+\- ]{10,}$/
    }

    constructor(props) {
        super(props);
        const storeState = localStorage.getItem('lscState');
        const {storageDate, ...loadState} = storeState ? JSON.parse(storeState) : {storageDate: 0};
        const storageAge = new Date() - Date.parse(storageDate);
        this.state = storageAge < 86400000 ? {
            ...App.defaultState,
            ...loadState,
            pendingAlert: true,
            pricesConf: null
        } : App.defaultState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentDidMount() {
        fetch(App.apiUrl + '/api/prices')
            .then(response => response.json())
            .then(data => this.setState({pricesConf: data}));
    }

    handleChange(partialState) {
        this.setState(partialState, () => {
            const {discount, discountCode, hadFocus, submitError, pendingAlert, pricesConf, ...storeState} = this.state;
            storeState.storageDate = new Date();
            localStorage.setItem('lscState', JSON.stringify(storeState));
        });
    }

    handleSubmit() {
        const {valid, hadFocus, submitError, pendingAlert, pricesConf, discount, ...submissionData} = this.state;
        const allValid = this.validateFields();
        if (allValid) {
            this.setState({submitError: false});
            fetch(App.apiUrl + '/api/submission', {
                method: 'POST',
                body: JSON.stringify(submissionData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        this.setState({submitError: true});
                        console.error(`Submission failed. ${data.error}.`);
                    } else {
                        // TODO: Remove conditional approach
                        if (process.env.NODE_ENV === 'production') {
                            localStorage.removeItem('lscState');
                            window.location = 'https://littlesheepanimatie.nl/bedankpagina/?uid=' + data.uid;
                        } else {
                            console.log(data);
                        }
                    }
                });
        } else {
            this.setState({submitError: true})
        }
    }

    validateFields(fields = ['style', 'wishes', 'name', 'company', 'email', 'phone']) {
        let valid = {};
        for (let field of fields) {
            const pattern = App.fieldPatterns[field];
            const value = this.state[field];
            valid[field] = pattern.test(value);
        }
        this.setState(state => ({
            valid: {...state.valid, ...valid},
            hadFocus: Object.keys(state.hadFocus).reduce((acc, key) => ({...acc, [key]: true}), {})
        }));
        return Object.values(valid).every(Boolean);
    }

    resetState() {
        // TODO: Remove usages
        this.setState(App.defaultState, () => {
            localStorage.removeItem('lscState');
        });
    }

    render() {
        const {pricesConf} = this.state;
        return pricesConf ? (
            <LocaleContext.Provider value={Cookies.get('i18next')}>
                <div className="lsc-container">
                    <OverviewTranslated changeHandler={this.handleChange} currentState={this.state}
                                        prices={pricesConf} resetState={this.resetState}/>
                    <Controls changeHandler={this.handleChange} currentState={this.state} prices={pricesConf}
                              resetState={this.resetState} submitHandler={this.handleSubmit}/>
                </div>
            </LocaleContext.Provider>
        ) : '';
    }
}

class Overview extends Component {
    getStyleString(showByline = true, noStyle = '') {
        const {t, currentState} = this.props;
        const currentStyle = currentState.style;
        if (currentStyle) {
            const currentStyleNames = {
                name: t(`sections.style.boxes.${currentStyle}.name`),
                byline: t(`sections.style.boxes.${currentStyle}.byline`)
            };
            let styleString = currentStyleNames.name;
            styleString += showByline ? ` (${currentStyleNames.byline})` : '';
            return styleString;
        } else {
            return noStyle;
        }
    }

    getOptionsString() {
        const {t, currentState} = this.props;
        const {style, ...additionalOptions} = App.defaultOptions;
        let hasAdditionalOptions = false;
        for (let option in additionalOptions) {
            if (additionalOptions[option] !== currentState[option]) {
                hasAdditionalOptions = true;
                break;
            }
        }
        return hasAdditionalOptions ? ' + ' + t('overview.additionalOptions') : '';
    }

    calculateTotalPrice(considerDiscount = true) {
        const {currentState, prices} = this.props;

        let totalPrice = 0.0;
        for (let option in App.defaultOptions) {
            let optionPriceData = prices[option];
            let optionState = currentState[option];
            let optionPrice = getOptionPrice(optionPriceData, optionState, currentState);
            totalPrice += optionPrice;
        }
        if (considerDiscount && currentState.discount) {
            const discountFactor = 1 - (currentState.discount / 100);
            totalPrice = (totalPrice * discountFactor).toFixed(2);
        }
        return totalPrice;
    }

    render() {
        const {
            t,
            currentState,
            resetState,
            changeHandler
        } = this.props;

        const {
            style,
            subtitles,
            multipleLanguages,
            pendingAlert
        } = currentState;


        const totalPrice = this.calculateTotalPrice();
        const originalPrice = this.calculateTotalPrice(false);
        const barStyleString = this.getStyleString(true, `<em>${t('sections.general.chooseStyle')}</em>`)

        return (
            <>
                <OverviewBar totalPrice={totalPrice} originalPrice={originalPrice}
                             style={style} styleString={barStyleString}/>
                <div className="lsc-overview">
                    {pendingAlert &&
                        <Alert text={t('main.pendingAlert')} changeHandler={changeHandler} breakpoint="sm"/>}
                    <h2 className="lsc-tool-heading lsc-tool-heading-sm" onDoubleClick={resetState}>
                        {t('main.title')}
                    </h2>
                    <div className="lsc-fixed">
                        <Videos style={style} subtitles={subtitles} multipleLanguages={multipleLanguages}/>
                        {style && <div className="lsc-price-info" role="status"
                                       aria-live="polite" aria-relevant="all">
                            <h5 className="lsc-price-title" id="lsc-price-title">{t('overview.priceTitle')}</h5>
                            <span className="lsc-price-details" aria-atomic="true">
                                {this.getStyleString()}{this.getOptionsString()}
                            </span>
                            <TotalPrice totalPrice={totalPrice} originalPrice={originalPrice}/>
                        </div>}
                    </div>
                    <Contact/>
                </div>
            </>
        )
    }
}

const OverviewTranslated = withTranslation('common')(Overview)

function Videos(props) {
    const {
        style,
        subtitles,
        multipleLanguages
    } = props;

    const {i18n} = useTranslation('common');
    const toolLang = i18n.languages[i18n.languages.length - 1].toUpperCase();

    const videoRefs = useRef({});
    const styleRef = useRef('high');
    const subtitleRef = useRef(App.defaultOptions.subtitles);
    const multiLangRef = useRef(App.defaultOptions.multipleLanguages);
    useEffect(() => {
        const prevSubLang = multiLangRef.current === '' ? (subtitleRef.current ? 'Sub' : '') : 'Multi';
        const prevRef = styleRef.current + prevSubLang;
        const prevVideo = videoRefs.current[prevRef];
        const prevTime = prevVideo.currentTime;
        const curSubLang = multipleLanguages === '' ? (subtitles ? 'Sub' : '') : 'Multi';
        const curRef =  style + curSubLang;
        const curVideo = videoRefs.current[curRef];
        if (curVideo) {
            curVideo.currentTime = prevTime;
            curVideo.play();
        }
        if (style) { styleRef.current = style }
        subtitleRef.current = subtitles;
        multiLangRef.current = multipleLanguages;
    }, [style, subtitles, multipleLanguages]);

    return (
        <>
            <Video src={process.env.PUBLIC_URL + '/video/LSA-2DStandaard-Brons.mp4'}
                   active={style === 'low' && subtitles === false && multipleLanguages === ''}
                   passRef={(element) => {videoRefs.current['low'] = element}}/>

            <Video src={process.env.PUBLIC_URL + '/video/LSA-2DLuxe-Zilver.mp4'}
                   active={style === 'middle' && subtitles === false && multipleLanguages === ''}
                   passRef={(element) => {videoRefs.current['middle'] = element}}/>

            <Video src={process.env.PUBLIC_URL + '/video/LSA-3DLuxe-Goud.mp4'}
                   active={(style === 'high' && subtitles === false && multipleLanguages === '') || !style}
                   passRef={(element) => {videoRefs.current['high'] = element}}/>

            <Video src={process.env.PUBLIC_URL + `/video/LSA-2DStandaard-Brons-CC-${toolLang}.mp4`}
                   active={style === 'low' && subtitles === true && multipleLanguages === ''}
                   passRef={(element) => {videoRefs.current['lowSub'] = element}}/>

            <Video src={process.env.PUBLIC_URL + `/video/LSA-2DLuxe-Zilver-CC-${toolLang}.mp4`}
                   active={style === 'middle' && subtitles === true && multipleLanguages === ''}
                   passRef={(element) => {videoRefs.current['middleSub'] = element}}/>

            <Video src={process.env.PUBLIC_URL + `/video/LSA-3DLuxe-Goud-CC-${toolLang}.mp4`}
                   active={style === 'high' && subtitles === true && multipleLanguages === ''}
                   passRef={(element) => {videoRefs.current['highSub'] = element}}/>

            <Video src={process.env.PUBLIC_URL + '/video/LSA-2DStandaard-Brons-CC-JP.mp4'}
                   active={style === 'low' && multipleLanguages !== ''}
                   passRef={(element) => {videoRefs.current['lowMulti'] = element}}/>

            <Video src={process.env.PUBLIC_URL + '/video/LSA-2DLuxe-Zilver-CC-JP.mp4'}
                   active={style === 'middle' && multipleLanguages !== ''}
                   passRef={(element) => {videoRefs.current['middleMulti'] = element}}/>

            <Video src={process.env.PUBLIC_URL + '/video/LSA-3DLuxe-Goud-CC-JP.mp4'}
                   active={style === 'high' && multipleLanguages !== ''}
                   passRef={(element) => {videoRefs.current['highMulti'] = element}}/>
        </>
    )
}

function Video(props) {
    const {
        src,
        active,
        passRef
    } = props;

    return (
        <video autoPlay loop muted playsInline preload="auto" aria-hidden={!active}
               src={src} className={"lsc-video" + (active ? " active" : "")} ref={passRef}/>
    )
}

function Contact(props) {
    const {t} = useTranslation('common');
    return (
        <aside className="lsc-contact" aria-labelledby="lsc-contact-cta">
            <span className="lsc-contact-cta" id="lsc-contact-cta">{t('overview.cta')}</span>
            <a href="tel:+31208208916" className="lsc-contact-link">
                <TelephoneIcon className="lsc-telephone-icon" aria-label="Bel"/>
                020 820 8916
            </a>
        </aside>
    )
}

function TotalPrice(props) {
    const {t} = useTranslation('common');
    const locale = useContext(LocaleContext);
    const {
        totalPrice,
        originalPrice
    } = props;
    const taxPrice = (totalPrice * 1.21).toFixed(2);

    return (
        <div className="lsc-prices" aria-atomic="true">
            <span className="lsc-price-main">
                {totalPrice !== originalPrice && <span className="lsc-price-original">
                    {formatPrice(originalPrice)}</span>} {formatPrice(totalPrice, locale)}
            </span>
            <span className="lsc-price-secondary">
                {formatPrice(taxPrice, locale, 2, true)} {t('overview.inclTax')}
            </span>
        </div>
    )
}

function OverviewBar(props) {
    const locale = useContext(LocaleContext);
    const {
        style,
        styleString,
        totalPrice,
        originalPrice
    } = props;

    return (
        <div className="lsc-overview-bar">
            <div className="lsc-overview-bar-inner">
                <span className="lsc-overview-bar-style" dangerouslySetInnerHTML={{__html: styleString}}/>
                {style && <span className="lsc-overview-bar-total">
                    {totalPrice !== originalPrice &&
                        <span className="lsc-price-original">{formatPrice(originalPrice)}</span>}
                    {formatPrice(totalPrice, locale)}
                </span>}
            </div>
        </div>
    )
}

function Controls(props) {
    const {t} = useTranslation('common');
    const {
        changeHandler,
        currentState,
        prices,
        submitHandler,
        resetState
    } = props;
    const {
        discount,
        discountCode,
        submitError,
        pendingAlert
    } = currentState;

    return (
        <div className="lsc-controls" role="form" aria-labelledby="lsc-tool-heading">
            {pendingAlert && <Alert text={t('main.pendingAlert')} changeHandler={changeHandler} breakpoint="lg"/>}
            <h1 className="lsc-tool-heading lsc-tool-heading-lg" id="lsc-tool-heading" onDoubleClick={resetState}>
                {t('main.title')}
            </h1>
            <Sections changeHandler={changeHandler} currentState={currentState} prices={prices}/>
            <DiscountTranslated discount={discount} discountCode={discountCode} changeHandler={changeHandler}/>
            <SubmitButton submitHandler={submitHandler} submitError={submitError}/>
        </div>
    )
}

class Alert extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const {changeHandler} = this.props;
        changeHandler({pendingAlert: false});
    }

    render() {
        const {
            text,
            breakpoint
        } = this.props;

        return (
            <div className={"lsc-alert" + (breakpoint ? ` lsc-alert-${breakpoint}` : "")} role="alert">
                <span className="lsc-alert-text">{text}</span>
                <span className="lsc-alert-close" onClick={this.handleClick} aria-hidden="true"></span>
            </div>
        )
    }
}

function Sections(props) {
    const {t} = useTranslation('common');
    const {
        changeHandler,
        currentState,
        prices
    } = props;

    return (
        <div className="lsc-sections">
            <RadioBoxes name={t('sections.style.name')} moreInfo={t('sections.general.moreInfo')} boxes={[
                {
                    slug: "high",
                    name: t('sections.style.boxes.high.name'),
                    byline: t('sections.style.boxes.high.byline'),
                    aspects: t('sections.style.boxes.high.aspects'),
                    price: prices.style.values.high,
                    moreInfoUrl: t('sections.style.boxes.high.moreInfoUrl')
                },
                {
                    slug: "middle",
                    name: t('sections.style.boxes.middle.name'),
                    byline: t('sections.style.boxes.middle.byline'),
                    aspects: t('sections.style.boxes.middle.aspects'),
                    price: prices.style.values.middle,
                    moreInfoUrl: t('sections.style.boxes.middle.moreInfoUrl')
                },
                {
                    slug: "low",
                    name: t('sections.style.boxes.low.name'),
                    byline: t('sections.style.boxes.low.byline'),
                    aspects: t('sections.style.boxes.low.aspects'),
                    price: prices.style.values.low,
                    moreInfoUrl: t('sections.style.boxes.low.moreInfoUrl')
                }
            ]} changeHandler={changeHandler} slug="style" value={currentState.style}/>
            <Slider name={t('sections.duration.name')} desc={t('sections.duration.desc')}
                    singleSuffix={t('sections.duration.singleSuffix')} multiSuffix={t('sections.duration.multiSuffix')}
                    zeroPrice={currentState.style ? t('sections.general.default') :
                        `<em>${t('sections.general.chooseStyle')}</em>`}
                    price={getOptionPrice(prices.duration, currentState.duration, currentState)}
                    changeHandler={changeHandler} slug="duration" value={currentState.duration}
                    label={t('sections.duration.label')}/>
            <CheckBoxes name={t('sections.subtitles.name')} desc={t('sections.subtitles.desc')}
                        boxes={[{
                            slug: true,
                            name: t('sections.subtitles.boxName'),
                            price: prices.subtitles.value
                        }]}
                        changeHandler={changeHandler} slug="subtitles" value={currentState.subtitles}/>
            <Numerics name={t('sections.multipleLanguages.name')} desc={t('sections.multipleLanguages.desc')}
                      fields={[{
                          name: t('sections.multipleLanguages.boxName'),
                          price: getOptionPrice(prices.multipleLanguages,
                              currentState.multipleLanguages, currentState)
                      }]}
                      changeHandler={changeHandler} slug="multipleLanguages"
                      value={currentState.multipleLanguages}/>
            <CheckBoxes name={t('sections.teaser.name')} desc={t('sections.teaser.desc')}
                        boxes={[{slug: true, name: t('sections.teaser.boxName'), price: prices.teaser.value}]}
                        changeHandler={changeHandler} slug="teaser" value={currentState.teaser}/>
            <Numerics name={t('sections.teaserMultipleLanguages.name')}
                      desc={t('sections.teaserMultipleLanguages.desc')}
                      fields={[{
                          name: t('sections.teaserMultipleLanguages.boxName'),
                          price: getOptionPrice(prices.teaserMultipleLanguages,
                              currentState.teaserMultipleLanguages, currentState)
                      }]}
                      changeHandler={changeHandler} slug="teaserMultipleLanguages"
                      value={currentState.teaserMultipleLanguages}/>
            <CheckBoxes name={t('sections.images.name')} desc={t('sections.images.desc')}
                        boxes={[{slug: true, name: t('sections.images.boxName'), price: prices.images.value}]}
                        changeHandler={changeHandler} slug="images" value={currentState.images}/>
            <CheckBoxes radio={true} name={t('sections.deadline.name')} desc={t('sections.deadline.desc')}
                        boxes={[
                            {
                                slug: "1month", name: t('sections.deadline.boxes.1month.name'),
                                price: prices.deadline.values["1month"].value,
                                zeroPrice: t('sections.general.default')
                            },
                            {
                                slug: "2weeks", name: t('sections.deadline.boxes.2weeks.name'),
                                price: getOptionPrice(prices.deadline.values["2weeks"],
                                    currentState.deadline, currentState),
                                zeroPrice: `<em>${t('sections.general.chooseStyle')}</em>`
                            },
                            {
                                slug: "1week", name: t('sections.deadline.boxes.1week.name'),
                                price: getOptionPrice(prices.deadline.values["1week"],
                                    currentState.deadline, currentState),
                                zeroPrice: `<em>${t('sections.general.chooseStyle')}</em>`
                            }
                        ]}
                        changeHandler={changeHandler} slug="deadline" value={currentState.deadline}/>
            <TextArea name={t('sections.wishes.name')} desc={t('sections.wishes.desc')} slug="wishes"
                      placeholder={t('sections.wishes.placeholder')} separator={false} changeHandler={changeHandler}
                      value={currentState.wishes} valid={currentState.valid.wishes}
                      hadFocus={currentState.hadFocus.wishes} emptyFeedback={t('sections.wishes.emptyFeedback')}
                      invalidFeedback={t('sections.wishes.invalidFeedback')}
            />
            <Texts name={t('sections.personal.name')} desc={t('sections.personal.desc')} changeHandler={changeHandler}
                   fields={[
                       {
                           slug: "name",
                           placeholder: t('sections.personal.fields.name.placeholder'),
                           value: currentState.name,
                           valid: currentState.valid.name,
                           hadFocus: currentState.hadFocus.name,
                           emptyFeedback: t('sections.personal.fields.name.feedback'),
                           invalidFeedback: t('sections.personal.fields.name.feedback')
                       },
                       {
                           slug: "company",
                           placeholder: t('sections.personal.fields.company.placeholder'),
                           value: currentState.company,
                           valid: currentState.valid.company,
                           hadFocus: currentState.hadFocus.company,
                           emptyFeedback: t('sections.personal.fields.company.feedback'),
                           invalidFeedback: t('sections.personal.fields.company.feedback')
                       },
                       {
                           slug: "email",
                           type: "email",
                           placeholder: t('sections.personal.fields.email.placeholder'),
                           value: currentState.email,
                           valid: currentState.valid.email,
                           hadFocus: currentState.hadFocus.email,
                           emptyFeedback: t('sections.personal.fields.email.emptyFeedback'),
                           invalidFeedback: t('sections.personal.fields.email.invalidFeedback')
                       },
                       {
                           slug: "phone",
                           type: "tel",
                           placeholder: t('sections.personal.fields.phone.placeholder'),
                           value: currentState.phone,
                           valid: currentState.valid.phone,
                           hadFocus: currentState.hadFocus.phone,
                           emptyFeedback: t('sections.personal.fields.phone.emptyFeedback'),
                           invalidFeedback: t('sections.personal.fields.phone.invalidFeedback')
                       }
                   ]}/>
        </div>
    )
}

class Section extends Component {
    static defaultProps = {
        separator: true
    }

    render() {
        const {
            name,
            desc,
            separator,
            slug,
            children
        } = this.props;

        return (
            <section className={"lsc-section" + (desc ? "" : " no-description") + (separator ? "" : " no-separator")}
                     aria-labelledby={`lsc-section-${slug}-heading`}
                     aria-describedby={desc && `lsc-section-${slug}-description`}>
                <h3 className="lsc-section-heading" id={`lsc-section-${slug}-heading`}>{name}</h3>
                {desc && <p className="lsc-section-description" id={`lsc-section-${slug}-description`}>{desc}</p>}
                {children}
            </section>
        )
    }
}

class RadioBoxes extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(boxID) {
        const {changeHandler, slug, boxes} = this.props;
        changeHandler({[slug]: boxes[boxID].slug})
    }

    checkActive(boxID) {
        const {value, boxes} = this.props;
        return value === boxes[boxID].slug
    }

    render() {
        const {
            name,
            slug,
            boxes,
            moreInfo
        } = this.props;

        return (
            <Section name={name} slug={slug}>
                <div className="lsc-radioboxes" role="radiogroup" aria-required="true">
                    {boxes.map((box, i) => <RadioBox key={i} ID={i} name={box.name} byline={box.byline}
                                                     slug={`${slug}-${box.slug}`}
                                                     price={box.price} aspects={box.aspects}
                                                     moreInfo={moreInfo} moreInfoUrl={box.moreInfoUrl}
                                                     active={this.checkActive(i)}
                                                     changeHandler={this.handleChange}/>)}
                </div>
            </Section>
        );
    }
}

class RadioBox extends Component {
    static contextType = LocaleContext;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleClick() {
        this.props.changeHandler(this.props.ID);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.handleClick();
        }
    }

    render() {
        const {
            name,
            byline,
            slug,
            price,
            aspects,
            moreInfo,
            moreInfoUrl,
            active
        } = this.props;

        return (
            <div className={"lsc-radiobox" + (active ? " active" : "")}
                 onClick={this.handleClick} onKeyDown={this.handleKeyPress}
                 tabIndex="0" role="radio" aria-checked={active}
                 aria-labelledby={`lsc-radiobox-${slug}-name lsc-radiobox-${slug}-byline`}
                 aria-describedby={`lsc-radiobox-${slug}-price lsc-radiobox-${slug}-aspects`}>
                <h4 className="lsc-option-name" id={`lsc-radiobox-${slug}-name`}>{name}</h4>
                <span className="lsc-option-byline" id={`lsc-radiobox-${slug}-byline`}>{byline}</span>
                <span className="lsc-option-price" id={`lsc-radiobox-${slug}-price`} aria-atomic="true">
                    {formatPrice(price, this.context)}
                </span>
                <div className="lsc-option-details">
                    <ul className="lsc-option-aspects" id={`lsc-radiobox-${slug}-aspects`}>
                        {aspects.map((aspect, i) => <li key={i}>{aspect}</li>)}
                    </ul>
                    <a className="lsc-option-readmore" href={moreInfoUrl} target="_blank" rel="noreferrer">
                        {moreInfo} &rsaquo;
                    </a>
                </div>
            </div>
        )
    }
}

class CheckBoxes extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.checkActive = this.checkActive.bind(this);
    }

    handleChange(boxID) {
        const {
            radio,
            slug,
            boxes,
            changeHandler
        } = this.props;

        if (radio) {
            changeHandler({[slug]: boxes[boxID].slug})
        } else {
            changeHandler(state => {
                if (boxes.length === 1 && typeof state[slug] == "boolean") {
                    return {[slug]: !state[slug]};
                } else {
                    let currentActive = [...state[slug]];
                    let boxIndex = currentActive.indexOf(boxes[boxID].slug);
                    boxIndex === -1 ? currentActive.push(boxes[boxID].slug) : currentActive.splice(boxIndex, 1);
                    return {[slug]: currentActive};
                }
            });
        }
    }

    checkActive(boxID) {
        const {
            radio,
            boxes,
            value
        } = this.props;

        if (radio) {
            return value === boxes[boxID].slug;
        } else if (boxes.length === 1 && typeof value == "boolean") {
            return value;
        } else {
            return value.includes(boxes[boxID].slug);
        }
    }

    getSlug(boxID) {
        const {
            boxes,
            slug
        } = this.props;

        if (boxes.length === 1) {
            return slug;
        } else {
            return `${slug}-${boxes[boxID].slug}`;
        }
    }

    render() {
        const {
            name,
            desc,
            slug,
            boxes,
            radio
        } = this.props;

        return (
            <Section name={name} desc={desc} slug={slug}>
                <div className="lsc-checkboxes" role={radio ? 'radiogroup' : 'group'}>
                    {boxes.map((box, i) => <CheckBox key={i} ID={i} name={box.name} price={box.price}
                                                     slug={this.getSlug(i)} radio={radio}
                                                     zeroPrice={box.zeroPrice} active={this.checkActive(i)}
                                                     changeHandler={this.handleChange}/>)}
                </div>
            </Section>
        );
    }
}

class CheckBox extends Component {
    static contextType = LocaleContext;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleClick() {
        this.props.changeHandler(this.props.ID);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.handleClick();
        }
    }

    render() {
        const {
            name,
            slug,
            price,
            zeroPrice,
            active,
            radio
        } = this.props;

        return (
            <div className="lsc-checkbox">
                <div className={"lsc-checkbox-box" + (active ? " active" : "")}
                     onClick={this.handleClick} onKeyDown={this.handleKeyPress}
                     tabIndex="0" role={radio ? 'radio' : 'checkbox'} aria-checked={active}
                     aria-labelledby={`lsc-checkbox-${slug}-label`} aria-describedby={`lsc-numeric-${slug}-price`}
                />
                <label className="lsc-option-name" id={`lsc-checkbox-${slug}-label`} onClick={this.handleClick}>
                    {name}
                </label>
                <span className="lsc-option-price" id={`lsc-numeric-${slug}-price`} aria-atomic="true"
                      dangerouslySetInnerHTML={{
                          __html: zeroPrice && price === 0 ?
                              zeroPrice : formatPrice(price, this.context)
                      }}/>
            </div>
        )
    }
}

class Slider extends Component {
    static contextType = LocaleContext;

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const {changeHandler, slug} = this.props;
        changeHandler({[slug]: event.target.value ? parseFloat(event.target.value) : ''})
    }

    getValueText() {
        const {
            value,
            multiSuffix,
            singleSuffix
        } = this.props;
        return `${formatNumber(value, this.context)} ${value > 1 ? multiSuffix : singleSuffix}`;
    }

    render() {
        const {
            name,
            desc,
            slug,
            label,
            price,
            zeroPrice,
            value
        } = this.props;

        return (
            <Section name={name} desc={desc} slug={slug}>
                <div className="lsc-slider">
                    <input type="range" min="1" max="5" step="0.5" className="lsc-slider-input"
                           value={value} onChange={this.handleChange}
                           aria-label={label} aria-describedby={`lsc-numeric-${slug}-price`}
                           aria-valuetext={this.getValueText()}/>
                    <label className="lsc-option-name" id={`lsc-numeric-${slug}-label`}>{this.getValueText()}</label>
                    <span className="lsc-option-price" id={`lsc-numeric-${slug}-price`} aria-atomic="true"
                          dangerouslySetInnerHTML={{
                              __html: zeroPrice && price === 0 ?
                                  zeroPrice : formatPrice(price, this.context)
                          }}/>
                </div>
            </Section>
        )
    }
}

function Numerics(props) {
    const {
        name,
        desc,
        slug,
        fields,
        value,
        changeHandler
    } = props;

    return (
        <Section name={name} desc={desc} slug={slug}>
            <div className="lsc-numerics">
                {fields.map((field, i) => <Numeric key={i} ID={i} name={field.name}
                                                   price={field.price} zeroPrice={field.zeroPrice}
                                                   slug={slug} changeHandler={changeHandler} value={value}
                />)}
            </div>
        </Section>
    )
}

class Numeric extends Component {
    static contextType = LocaleContext;

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.inputRef = createRef();
    }

    handleChange(event) {
        const {slug, changeHandler} = this.props;
        const value = event.target.value;
        if (value >= 1 && value < 100) {
            changeHandler({[slug]: value ? parseInt(value) : ''})
        } else if (value >= 100) {
            return false;
        } else {
            changeHandler({[slug]: ''})
        }
    }

    setFocus() {
        this.inputRef.current.focus();
    }

    preventWheel(e) {
        e.target.blur();
    }

    render() {
        const {
            name,
            slug,
            price,
            value
        } = this.props;

        return (
            <div className="lsc-numeric">
                <input type="number" className="lsc-numeric-input" min="0" max="99" pattern="[0-9]*"
                       placeholder={"\u2026"} value={value} onChange={this.handleChange} ref={this.inputRef}
                       onWheel={this.preventWheel} aria-labelledby={`lsc-numeric-${slug}-label`}
                       aria-describedby={`lsc-numeric-${slug}-price`}/>
                <label className="lsc-option-name" onClick={this.setFocus} id={`lsc-numeric-${slug}-label`}>
                    {name}
                </label>
                <span className="lsc-option-price" id={`lsc-numeric-${slug}-price`} aria-atomic="true">
                    {formatPrice(price)}
                </span>
            </div>
        )
    }
}

class TextArea extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleChange(event) {
        const {changeHandler, slug} = this.props;
        changeHandler(state => ({
            [slug]: event.target.value ? event.target.value : '',
            valid: {...state.valid, [slug]: App.fieldPatterns[slug].test(event.target.value)}
        }));
    }

    setHadFocus(bool) {
        const {changeHandler, slug} = this.props;
        changeHandler(state => ({
            hadFocus: {...state.hadFocus, [slug]: bool}
        }));
    }

    handleFocus(event) {
        if (this.props.valid) {
            this.setHadFocus(false);
        }
    }

    handleBlur(event) {
        this.setHadFocus(true);
    }

    render() {
        const {
            name,
            desc,
            slug,
            placeholder,
            separator,
            value,
            valid,
            hadFocus,
            emptyFeedback,
            invalidFeedback
        } = this.props;

        return (
            <Section name={name} desc={desc} separator={separator} slug={slug}>
                <div className={"lsc-textarea" + (hadFocus && !valid ? " invalid" : "")}>
                    {valid || <span className="lsc-field-feedback" id={`lsc-${slug}-feedback`} role="alert">
                        {value.length ? invalidFeedback : emptyFeedback}</span>}
                    <textarea className="lsc-textarea-input" value={value} onChange={this.handleChange}
                              onFocus={this.handleFocus} onBlur={this.handleBlur}
                              rows="8" name={slug} placeholder={placeholder}
                              required aria-invalid={!valid}
                              aria-describedby={valid ? '' : `lsc-${slug}-feedback`}/>
                </div>
            </Section>
        )
    }
}

function Texts(props) {
    const {
        name,
        desc,
        slug,
        fields,
        changeHandler
    } = props;

    return (
        <Section name={name} desc={desc} slug={slug}>
            <div className="lsc-texts">
                {fields.map((field, i) => <Text key={i} ID={i} slug={field.slug} value={field.value}
                                                changeHandler={changeHandler} placeholder={field.placeholder}
                                                hadFocus={field.hadFocus} valid={field.valid}
                                                emptyFeedback={field.emptyFeedback}
                                                invalidFeedback={field.invalidFeedback}/>)}
            </div>
        </Section>
    )
}

class Text extends Component {
    static defaultProps = {
        type: 'text'
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleChange(event) {
        const {changeHandler, slug} = this.props;
        changeHandler(state => ({
            [slug]: event.target.value ? event.target.value : '',
            valid: {...state.valid, [slug]: App.fieldPatterns[slug].test(event.target.value)}
        }));
    }

    setHadFocus(bool) {
        const {changeHandler, slug} = this.props;
        changeHandler(state => ({
            hadFocus: {...state.hadFocus, [slug]: bool}
        }));
    }

    handleFocus(event) {
        if (this.props.valid) {
            this.setHadFocus(false);
        }
    }

    handleBlur(event) {
        this.setHadFocus(true);
    }

    render() {
        const {
            slug,
            placeholder,
            value,
            type,
            valid,
            hadFocus,
            invalidFeedback,
            emptyFeedback
        } = this.props;

        return (
            <div className={"lsc-text-group" + (hadFocus && !valid ? " invalid" : "")}>
                {valid || <span className="lsc-field-feedback" id={`lsc-${slug}-feedback`} role="alert">
                    {value.length ? invalidFeedback : emptyFeedback}</span>}
                <input type={type} className="lsc-text" value={value}
                       onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur}
                       name={slug} placeholder={placeholder}
                       required aria-invalid={!valid}
                       aria-describedby={valid ? '' : `lsc-${slug}-feedback`}/>
            </div>
        )
    }
}

class Discount extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false}
        this.handleChange = this.handleChange.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleChange(event) {
        clearTimeout(this.validateTimeout);
        this.props.changeHandler({
            discountCode: event.target.value
        });
        this.validateTimeout = setTimeout(() => {
            fetch(App.apiUrl + '/api/discount', {
                method: 'POST',
                body: JSON.stringify({
                    code: event.target.value
                })
            })
                .then(response => response.json())
                .then(data => {
                    this.props.changeHandler({
                        discount: data.percentage
                    })
                });
        }, 300);
    }

    handleToggle() {
        this.setState((state) => ({
            open: !state.open
        }));
    }

    render() {
        const {open} = this.state;
        const {
            t,
            discount,
            discountCode
        } = this.props;

        return (
            <div className="lsc-discount">
                <button type="button" className="lsc-discount-toggle" onClick={this.handleToggle}
                        aria-expanded={open} aria-controls="lsc-discount-content">{t('discount.toggle')}</button>
                <div className={"lsc-discount-content" + (open ? " open" : "") + (discount ? " valid" : "")}
                     id="lsc-discount-content">
                    <input type="text" className="lsc-text" tabIndex={open ? 0 : -1} value={discountCode}
                           onChange={this.handleChange} name="discount" placeholder={t('discount.placeholder')}
                           aria-describedby={discount ? 'lsc-discount-status' : ''}/>
                    {discount && <span className="lsc-discount-status" id="lsc-discount-status">
                        {t('discount.status')}: {discount}%
                    </span>}
                </div>
            </div>
        )
    }
}

const DiscountTranslated = withTranslation('common')(Discount)

function SubmitButton(props) {
    const {t} = useTranslation('common');
    const {
        submitHandler,
        submitError
    } = props;

    return (
        <div className="lsc-submit">
            {submitError && <span className="lsc-error" dangerouslySetInnerHTML={{__html: t('submit.error')}}/>}
            <button type="button" className="lsc-button" onClick={submitHandler}>{t('submit.button')}</button>
        </div>
    )
}

export default App;
